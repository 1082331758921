
















































































import Vue from 'vue'

interface Interest {
  transactionNumber: number
  paidFor: string
  paidBy: string
  benefitGenerated: string
}

interface TabItem {
  index: number
  title: string
  subtitle: string
}

interface ComponentData {
  items: Interest[]
  columns: Record<string, string>[]
  tabs: TabItem[]
  activeTab: TabItem
}

export default Vue.extend({
  data(): ComponentData {
    return {
      items: Array.from(Array(10), (_, index) => ({
        transactionNumber: index + 1,
        paidFor: 'kwabo',
        paidBy: 'Gildas Hountondji',
        benefitGenerated: '12 000 F'
      })),
      columns: [
        {
          label: 'N° transaction',
          field: 'transactionNumber'
        },
        {
          label: 'Payer Pour',
          field: 'paidFor'
        },
        {
          label: 'Payer Par',
          field: 'paidBy'
        },
        {
          label: 'Intérêt Généré',
          field: 'benefitGenerated'
        },
        {
          label: '',
          field: '_action',
          align: 'right'
        }
      ],
      tabs: Array.from(Array(10), (_, index) => ({
        index: index,
        title: `Groupe ${index + 1}`,
        subtitle: `${5 + index + 1} membres`
      })),
      activeTab: {
        index: 0,
        title: 'Groupe 1',
        subtitle: '6 membres'
      }
    }
  },

  methods: {
    onTabClick(index: number) {
      this.activeTab = {
        index: index,
        title: this.tabs[index].title,
        subtitle: this.tabs[index].subtitle
      }
    }
  }
})
